import { useAuthStore } from "@/store/auth/authStore";
import { useQueryClient } from "@tanstack/vue-query";

export default [
    {
        path: "/login",
        name: "login",
        component: () => import("@/views/auth/login"),
        meta: {
            title: "Login",
            authRequired: false,
            guestOnly: true,
        },
    },
    {
        path: "/forgot-password",
        name: "Forgot password",
        component: () => import("@/views/auth/forgot-password"),
        meta: {
            title: "Forgot Password",
            authRequired: false,
            guestOnly: true,
        },
    },
    {
        path: "/not-authorized",
        name: "not-authorized",
        component: () => import("@/views/auth/not-authorized"),
        meta: {
            title: "Not Authorized",
            authRequired: false,
        },
    },
    {
        path: "/profile",
        name: "profile",
        component: () => import("@/views/auth/profile"),
        meta: {
            title: "Profile",
            authRequired: true,
        },
    },

    /*** SUPERADMIN ROUTES */
    {
        path: "/superadmin/company",
        name: "superadmin.company",
        component: () => import("@/views/superadmin/company"),
        meta: {
            title: "Company",
            authRequired: true,
            roles: ["SuperAdmin"],
        },
    },
    {
        path: "/superadmin/company-type",
        name: "superadmin.company-type",
        component: () => import("@/views/superadmin/company-type"),
        meta: {
            title: "Company Type",
            authRequired: true,
            roles: ["SuperAdmin"],
        },
    },
    {
        path: "/superadmin/compliance",
        name: "superadmin.compliance",
        component: () => import("@/views/superadmin/compliance"),
        meta: {
            title: "Compliance",
            authRequired: true,
            roles: ["SuperAdmin"],
        },
    },
    /*** END OF SUPERADMIN ROUTES */

    /*** EMPLOYEE ROUTES ***/
    {
        path: "/employee/dashboard",
        name: "employee.dashboard",
        component: () => import("@/views/employee/dashboard"),
        meta: {
            title: "Employee Dashboard",
            authRequired: true,
            roles: [
                "Admin",
                "Employee",
                "Supervisor",
                "Operation",
                "Manager",
                "Account",
            ],
        },
    },
    /*** END OF EMPLOYEE ROUTES ***/

    /*** ADMIN ROUTES ***/

    {
        path: "/admin/dashboard",
        name: "admin.dashboard",
        meta: {
            title: "Admin Dashboard",
            authRequired: true,
            roles: ["Admin", "Supervisor", "Operation", "Manager", "User"],
        },
        component: () => import("@/views/admin/dashboard"),
    },

    {
        path: "/admin/operation",
        name: "admin.operation",
        meta: {
            title: "Operation",
            authRequired: true,
            roles: ["Admin"],
        },
        component: () => import("@/views/admin/operation/index.vue"),
    },

    {
        path: "/admin/timesheet",
        name: "admin.timesheet",
        meta: {
            title: "Timesheet",
            authRequired: true,
            roles: ["Admin"],
        },
        component: () => import("@/views/admin/timesheet/index.vue"),
    },

    {
        path: "/admin/employee",
        name: "admin.employee",
        meta: {
            title: "Employee",
            authRequired: true,
            roles: ["Admin"],
        },
        component: () => import("@/views/admin/employee/index.vue"),
    },

    {
        path: "/admin/clients",
        name: "admin.clients",
        meta: {
            title: "Clients",
            authRequired: true,
            roles: ["Admin"],
        },
        component: () => import("@/views/admin/clients/index.vue"),
    },

    {
        path: "/admin/payment",
        name: "admin.payment",
        meta: {
            title: "Payment",
            authRequired: true,
            roles: ["Admin"],
        },
        component: () => import("@/views/admin/payment/index.vue"),
    },

    {
        path: "/admin/report",
        name: "admin.report",
        meta: {
            title: "Report",
            authRequired: true,
            roles: ["Admin"],
        },
        component: () => import("@/views/admin/report/index.vue"),
    },

    {
        path: "/admin/settings",
        name: "admin.settings",
        meta: {
            title: "Settings",
            authRequired: true,
            roles: ["Admin"],
        },
        component: () => import("@/views/admin/setting/index.vue"),
    },

    {
        path: "/admin/message",
        name: "admin.message",
        meta: {
            title: "Message",
            authRequired: true,
            roles: ["Admin"],
        },
        component: () => import("@/views/admin/message"),
    },

    {
        path: "/admin/admin",
        name: "admin.admin",
        meta: {
            title: "Admin",
            authRequired: true,
            roles: ["Admin"],
        },
        component: () => import("@/views/admin/admin/index.vue"),
    },

    {
        path: "/logout",
        name: "logout",
        meta: {
            title: "Logout",
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                const auth = useAuthStore();
                const queryClient = useQueryClient();

                auth.logout();
                queryClient.clear();

                next();
            },
        },
        component: () => import("@/views/auth/logout/basic"),
    },
    /*** END OF ADMIN ROUTES ***/

    // OPERATION ROUTES
    {
        path: "/operation/dashboard",
        name: "operation.dashboard",
        meta: {
            title: "Operation Dashboard",
            authRequired: true,
            roles: ["Operation"],
        },
        component: () => import("@/views/admin/dashboard"),
    },

    {
        path: "/operation/operation",
        name: "operation.operation",
        meta: {
            title: "Operation",
            authRequired: true,
            roles: ["Operation"],
        },
        component: () => import("@/views/admin/operation/index.vue"),
    },

    {
        path: "/operation/timesheet",
        name: "operation.timesheet",
        meta: {
            title: "Timesheet",
            authRequired: true,
            roles: ["Operation"],
        },
        component: () => import("@/views/admin/timesheet/index.vue"),
    },

    {
        path: "/operation/employee",
        name: "operation.employee",
        meta: {
            title: "Employee",
            authRequired: true,
            roles: ["Operation"],
        },
        component: () => import("@/views/admin/employee/index.vue"),
    },

    {
        path: "/operation/clients",
        name: "operation.clients",
        meta: {
            title: "Clients",
            authRequired: true,
            roles: ["Operation"],
        },
        component: () => import("@/views/admin/clients/index.vue"),
    },

    {
        path: "/operation/payment",
        name: "operation.payment",
        meta: {
            title: "Payment",
            authRequired: true,
            roles: ["Operation"],
        },
        component: () => import("@/views/admin/payment/index.vue"),
    },

    {
        path: "/operation/report",
        name: "operation.report",
        meta: {
            title: "Report",
            authRequired: true,
            roles: ["Operation"],
        },
        component: () => import("@/views/admin/report/index.vue"),
    },

    {
        path: "/operation/settings",
        name: "operation.settings",
        meta: {
            title: "Settings",
            authRequired: true,
            roles: ["Operation"],
        },
        component: () => import("@/views/admin/setting/index.vue"),
    },

    {
        path: "/operation/message",
        name: "operation.message",
        meta: {
            title: "Message",
            authRequired: true,
            roles: ["Operation"],
        },
        component: () => import("@/views/admin/message"),
    },

    // END OF OPERATION ROUTES

    // MANAGER ROUTES
    {
        path: "/manager/dashboard",
        name: "manager.dashboard",
        meta: {
            title: "Manager Dashboard",
            authRequired: true,
            roles: ["Manager"],
        },
        component: () => import("@/views/admin/dashboard"),
    },

    {
        path: "/manager/operation",
        name: "manager.operation",
        meta: {
            title: "Operation",
            authRequired: true,
            roles: ["Manager"],
        },
        component: () => import("@/views/admin/operation/index.vue"),
    },

    {
        path: "/manager/timesheet",
        name: "manager.timesheet",
        meta: {
            title: "Timesheet",
            authRequired: true,
            roles: ["Manager"],
        },
        component: () => import("@/views/admin/timesheet/index.vue"),
    },

    {
        path: "/manager/employee",
        name: "manager.employee",
        meta: {
            title: "Employee",
            authRequired: true,
            roles: ["Manager"],
        },
        component: () => import("@/views/admin/employee/index.vue"),
    },

    {
        path: "/manager/clients",
        name: "manager.clients",
        meta: {
            title: "Clients",
            authRequired: true,
            roles: ["Manager"],
        },
        component: () => import("@/views/admin/clients/index.vue"),
    },

    {
        path: "/manager/payment",
        name: "manager.payment",
        meta: {
            title: "Payment",
            authRequired: true,
            roles: ["Manager"],
        },
        component: () => import("@/views/admin/payment/index.vue"),
    },

    {
        path: "/manager/report",
        name: "manager.report",
        meta: {
            title: "Report",
            authRequired: true,
            roles: ["Manager"],
        },
        component: () => import("@/views/admin/report/index.vue"),
    },

    {
        path: "/manager/settings",
        name: "manager.settings",
        meta: {
            title: "Settings",
            authRequired: true,
            roles: ["Manager"],
        },
        component: () => import("@/views/admin/setting/index.vue"),
    },

    {
        path: "/manager/message",
        name: "manager.message",
        meta: {
            title: "Message",
            authRequired: true,
            roles: ["Manager"],
        },
        component: () => import("@/views/admin/message"),
    },

    // END OF MANAGER ROUTES

    // ACCOUNT ROUTES
    {
        path: "/account/dashboard",
        name: "account.dashboard",
        meta: {
            title: "Account Dashboard",
            authRequired: true,
            roles: ["Account"],
        },
        component: () => import("@/views/admin/dashboard"),
    },

    {
        path: "/account/operation",
        name: "account.operation",
        meta: {
            title: "Operation",
            authRequired: true,
            roles: ["Account"],
        },
        component: () => import("@/views/admin/operation/index.vue"),
    },

    {
        path: "/account/timesheet",
        name: "account.timesheet",
        meta: {
            title: "Timesheet",
            authRequired: true,
            roles: ["Account"],
        },
        component: () => import("@/views/admin/timesheet/index.vue"),
    },

    {
        path: "/account/employee",
        name: "account.employee",
        meta: {
            title: "Employee",
            authRequired: true,
            roles: ["Account"],
        },
        component: () => import("@/views/admin/employee/index.vue"),
    },

    {
        path: "/account/clients",
        name: "account.clients",
        meta: {
            title: "Clients",
            authRequired: true,
            roles: ["Account"],
        },
        component: () => import("@/views/admin/clients/index.vue"),
    },

    {
        path: "/account/payment",
        name: "account.payment",
        meta: {
            title: "Payment",
            authRequired: true,
            roles: ["Account"],
        },
        component: () => import("@/views/admin/payment/index.vue"),
    },

    {
        path: "/account/report",
        name: "account.report",
        meta: {
            title: "Report",
            authRequired: true,
            roles: ["Account"],
        },
        component: () => import("@/views/admin/report/index.vue"),
    },

    {
        path: "/account/settings",
        name: "account.settings",
        meta: {
            title: "Settings",
            authRequired: true,
            roles: ["Account"],
        },
        component: () => import("@/views/admin/setting/index.vue"),
    },

    {
        path: "/account/message",
        name: "account.message",
        meta: {
            title: "Message",
            authRequired: true,
            roles: ["Account"],
        },
        component: () => import("@/views/admin/message"),
    },

    // END OF ACCOUNT ROUTES

    // SUPERVISOR ROUTES
    {
        path: "/supervisor/dashboard",
        name: "supervisor.dashboard",
        meta: {
            title: "Supervisor Dashboard",
            authRequired: true,
            roles: ["Supervisor"],
        },
        component: () => import("@/views/admin/dashboard"),
    },

    {
        path: "/supervisor/operation",
        name: "supervisor.operation",
        meta: {
            title: "Operation",
            authRequired: true,
            roles: ["Supervisor"],
        },
        component: () => import("@/views/admin/operation/index.vue"),
    },

    {
        path: "/supervisor/timesheet",
        name: "supervisor.timesheet",
        meta: {
            title: "Timesheet",
            authRequired: true,
            roles: ["Supervisor"],
        },
        component: () => import("@/views/admin/timesheet/index.vue"),
    },

    {
        path: "/supervisor/employee",
        name: "supervisor.employee",
        meta: {
            title: "Employee",
            authRequired: true,
            roles: ["Supervisor"],
        },
        component: () => import("@/views/admin/employee/index.vue"),
    },

    {
        path: "/supervisor/clients",
        name: "supervisor.clients",
        meta: {
            title: "Clients",
            authRequired: true,
            roles: ["Supervisor"],
        },
        component: () => import("@/views/admin/clients/index.vue"),
    },

    {
        path: "/supervisor/payment",
        name: "supervisor.payment",
        meta: {
            title: "Payment",
            authRequired: true,
            roles: ["Supervisor"],
        },
        component: () => import("@/views/admin/payment/index.vue"),
    },

    {
        path: "/supervisor/report",
        name: "supervisor.report",
        meta: {
            title: "Report",
            authRequired: true,
            roles: ["Supervisor"],
        },
        component: () => import("@/views/admin/report/index.vue"),
    },

    {
        path: "/supervisor/settings",
        name: "supervisor.settings",
        meta: {
            title: "Settings",
            authRequired: true,
            roles: ["Supervisor"],
        },
        component: () => import("@/views/admin/setting/index.vue"),
    },

    {
        path: "/supervisor/message",
        name: "supervisor.message",
        meta: {
            title: "Message",
            authRequired: true,
            roles: ["Supervisor"],
        },
        component: () => import("@/views/admin/message"),
    },

    // END OF ACCOUNT ROUTES
];
