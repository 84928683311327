const RoleMapper = (role) => {
    const roleMap = {
        1: "SuperAdmin",
        2: "Admin",
        3: "Employee",
        4: "Supervisor",
        5: "Operation",
        6: "Manager",
        7: "Account",
    };

    return roleMap[role] || "User";
};

export default RoleMapper;
